import { createRouter, createWebHistory } from "vue-router";

import { useStore } from 'vuex';

import LoginLayout from "../layouts/LoginLayout.vue";
import SignupForm from "../views/Auth/SignupForm.vue";
import SignupSuccess from "../views/Auth/SignupSuccess.vue";
import SignupConfirm from "../views/Auth/SignupConfirm.vue";
import LoginForm from "../views/Auth/LoginForm.vue";
import ResetForm from "../views/Auth/ResetForm.vue";
import ResetConfirm from "../views/Auth/ResetConfirm.vue";
import ResetPassForm from "../views/Auth/ResetPassForm.vue";
import ResetSuccess from "../views/Auth/ResetSuccess.vue";

import MainLayout from "../layouts/MainLayout.vue";
import HomeView from "../views/Main/HomeView.vue";
import AnimationsView from "../views/Main/AnimationsView.vue";
import FavoritesView from "../views/Main/FavoritesView.vue";
import AnimationPreview from "../views/AnimationPreview.vue";
import NotFound from "../views/NotFound.vue";
import UploadLayout from "@/layouts/UploadLayout.vue";
import UploadForm from "../views/UploadForm.vue";
import PromptForm from "../views/PromptForm.vue";

const DEFAULT_TITLE = 'AvaCapo Platform';

const routes = [
  { path: "/", redirect: "/signup" },
  {
    path: "/signup",
    component: LoginLayout,
    children: [
      { path: "", component: SignupForm },
      { path: "success", component: SignupSuccess },
      { path: "confirm/:token", component: SignupConfirm },
    ],
  },
  {
    path: "/login",
    component: LoginLayout,
    children: [{ path: "", component: LoginForm }],
  },
  {
    path: "/resetpass",
    component: LoginLayout,
    children: [
      { path: "", component: ResetForm },
      { path: "confirm", component: ResetConfirm },
      { path: "success", component: ResetSuccess },
      { path: "form", component: ResetPassForm },
    ],
  },
  { path: "/animation", component: AnimationPreview },
  { path: "/app", redirect: "/app/home" },
  {
    path: "/app",
    component: MainLayout,
    children: [
      { path: "home", component: HomeView },
      { path: "animations", component: AnimationsView },
      { path: "favorites", component: FavoritesView },
    ],
  },
  {
    path: "/upload",
    component: UploadLayout,
    children: [
      {path: "", component: UploadForm}
    ]
  },
  {
    path: "/prompt",
    component: UploadLayout,
    children: [
      {path: "", component: PromptForm}
    ]
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = DEFAULT_TITLE;
  const publicPages = ['/login', '/signup', '/resetpass'];
  const authRequired = !publicPages.some(page => to.path.startsWith(page));
  const store = useStore();

   const isLoggedIn = store.getters.isLoggedIn;

   if (isLoggedIn && (to.path === '/login' || to.path === '/signup')) {
     next('/app/home'); // Redirect to home if already logged in
   } 

  if (authRequired && !isLoggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;