<template>
  <!-- TODO: move to external component -->
  <div v-if="isLoading" class="loader-wrapper">
    <div class="header-2 loader-description">
      Please wait
    </div>

    <div v-if="isProcessing" class="caption loader-description">
      Your animation is being processed
    </div>

    <div v-if="!isProcessing" class="caption loader-description">
      Your animation is being downloaded
    </div>

    <div class="progress-placeholder">
      <svg class="progress-circle" width="100" height="100">
        <defs>
          <radialGradient id="progress-gradient" cx="50%" cy="50%" r="50%">
            <stop offset="26%" stop-color="#20AD8B" />
            <stop offset="76%" stop-color="#ACE41B" />
          </radialGradient>
        </defs>
        <g transform="rotate(-90, 50, 50)">
          <circle
            class="progress-circle-bg"
            cx="50"
            cy="50"
            r="45"
            fill="transparent"
            stroke="#eee"
            stroke-width="10"
          />
          <circle
            class="progress-circle-fg"
            cx="50"
            cy="50"
            r="45"
            fill="transparent"
            stroke="url(#progress-gradient)"
            stroke-width="10"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="
              circumference - loadingProgress * circumference
            "
            stroke-linecap="round"
          />
        </g>
      </svg>
    </div>

    <div v-if="isLoading && !isProcessing" class="progress-label">
      {{ formattedLoadedSize }} / {{ formattedTotalSize }} ({{ Math.round(loadingProgress * 100) }}%)
    </div>
  </div>


  <div v-if="!isLoading" class="animation-wrapper">
    <div class="anim-controls">
      <HoverPopover message="Show/hide the character's skeleton">
        <div class="control-btn" @click="toggleSkeletonVisibility">
          <BaseIcon iconName="skull"></BaseIcon>
        </div>
      </HoverPopover>

      <HoverPopover message="Rotate/Pan view">
        <div class="control-btn" @click="togglePanMode">
          <BaseIcon :iconName="isPanModeEnabled ? 'pan' : 'rotate'"></BaseIcon>
        </div>
      </HoverPopover>

      <HoverPopover message="Reset camera position">
        <div class="control-btn" @click="resetCamera">
          <BaseIcon iconName="camera"></BaseIcon>
        </div>
      </HoverPopover>
    </div>

    <div ref="threejsContainer" class="threejs-container" @dragover.prevent="handleDragOver"
      @drop.prevent="handleFileDrop" v-if="!props.animationId">
      You can drag and drop an FBX or GLB file here:
    </div>
    <div ref="threejsContainer" class="threejs-container" v-else></div>

    <div class="scale-controls">
      <HoverPopover message="Zoom in">
        <div class="control-btn" @click="zoomIn">
          <BaseIcon iconName="plus"></BaseIcon>
        </div>
      </HoverPopover> 
      <HoverPopover message="Zoom out">
        <div class="control-btn" @click="zoomOut">
          <BaseIcon iconName="minus"></BaseIcon>
        </div>
      </HoverPopover>
    </div>
  </div>

  <div v-if="!isLoading" class="animation-footer">
    <div class="control-btn locked" @click="handleHomeButton">
      <BaseIcon iconName="layers"
                color="rgba(255,255,255,0.4)">
      </BaseIcon>
    </div>

    <div class="playbar">
      <div class="progress-container" 
          @click="setAnimationProgress($event)" 
          @mousedown="startDragging" 
          @mouseup="stopDragging" 
          @mouseleave="stopDragging"
          @mousemove="dragUpdate">
        <div class="progress-bar" :style="{ width: progressBarWidth + '%' }"></div>
      </div>

      <div class="time-codes">
        <div>{{ formattedCurrentTime }}</div>
        <div>{{ formattedDuration }}</div>
      </div>
      <div class="playbar-controls">
        <div class="control-btn rotate" @click="previousFrame">
          <BaseIcon iconName="fastForward"></BaseIcon>
        </div>
        <div class="control-btn" @click="toggleAnimation">
          <BaseIcon :iconName="iconName"></BaseIcon>
        </div>
        <div class="control-btn" @click="nextFrame">
          <BaseIcon iconName="fastForward"></BaseIcon>
        </div>
        <div class="control-btn" @click="resetToFirstFrame">
          <BaseIcon iconName="rotateLeft"></BaseIcon>
        </div>
      </div>
    </div>

    <HoverPopover
      message="Do you have any questions regarding our service? If you have suggestions to share with us please send an email to <span class='gradient-text'>support@avacapo.com</span>">
      <div class="control-btn" @click="handleHomeButton">
        <BaseIcon iconName="help"></BaseIcon>
      </div>
    </HoverPopover>
  </div>
</template>

<script setup>
import BaseIcon from "@/components/BaseIcon.vue";
import HoverPopover from "@/components/HoverPopover.vue";

import { onMounted, onUnmounted, ref, reactive, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BVHLoader } from "three/examples/jsm/loaders/BVHLoader";  

const loaderRadius = 45;
const circumference = computed(() => 2 * Math.PI * loaderRadius);

const totalSize = ref(0); 
const loadedSize = ref(0); 

const formattedLoadedSize = computed(() => formatSize(loadedSize.value));
const formattedTotalSize = computed(() => formatSize(totalSize.value));

function formatSize(size) {
  if (size < 1024) return `${size} B`;
  const kb = size / 1024;
  if (kb < 1024) return `${kb.toFixed(1)} KB`;
  return `${(kb / 1024).toFixed(1)} MB`;
}

const props = defineProps({
  animationId: String,
});

const threejsContainer = ref(null);
const clock = new THREE.Clock();
let scene, camera, renderer, controls, skeletonHelper, loadedScene;
const mixers = [];
let animationActions = []; 

const initialCameraPosition = new THREE.Vector3(0, 5, 10);

const iconName = ref("pause");
const isPanModeEnabled = ref(false);
const zoomStep = 1;

const progressBarWidth = ref(0);
const animationDetails = reactive({
  duration: 0,
  currentTime: 0,
});
const isAnimationPlaying = ref(true);

const isLoading = ref(false);
const isProcessing = ref(false);
const loadingProgress = ref(0);

const formattedCurrentTime = computed(() =>
  formatTime(animationDetails.currentTime)
);
const formattedDuration = computed(() => formatTime(animationDetails.duration));

const currentFrame = ref(0);
const totalFrames = computed(() => {
  if (animationActions.length > 0 && animationActions[0].getClip()) {
    return animationActions[0].getClip().duration * 30;
  }
  return 0;
});


let modelBuffer = null;

const dragging = ref(false);

function loadBVHFile(file) {
  const reader = new FileReader();
  reader.onload = (event) => {
    const loader = new BVHLoader();
    const result = loader.parse(event.target.result);
    handleBVHLoaded(result);
  };
  reader.readAsText(file);
}

const boneMap = {
  // Main body
  'Hips': 'mixamorigHips',
  'Spine': 'mixamorigSpine',
  'Spine1': 'mixamorigSpine1',
  'Spine2': 'mixamorigSpine2',
  'Neck': 'mixamorigNeck',
  'Head': 'mixamorigHead',

  // Left Arm
  'LeftShoulder': 'mixamorigLeftShoulder',
  'LeftArm': 'mixamorigLeftArm',
  'LeftForeArm': 'mixamorigLeftForeArm',
  'LeftHand': 'mixamorigLeftHand',

  // Left Hand Fingers
  'LeftHandThumb1': 'mixamorigLeftHandThumb1',
  'LeftHandThumb2': 'mixamorigLeftHandThumb2',
  'LeftHandThumb3': 'mixamorigLeftHandThumb3',
  'LeftHandIndex1': 'mixamorigLeftHandIndex1',
  'LeftHandIndex2': 'mixamorigLeftHandIndex2',
  'LeftHandIndex3': 'mixamorigLeftHandIndex3',
  'LeftHandMiddle1': 'mixamorigLeftHandMiddle1',
  'LeftHandMiddle2': 'mixamorigLeftHandMiddle2',
  'LeftHandMiddle3': 'mixamorigLeftHandMiddle3',
  'LeftHandRing1': 'mixamorigLeftHandRing1',
  'LeftHandRing2': 'mixamorigLeftHandRing2',
  'LeftHandRing3': 'mixamorigLeftHandRing3',
  'LeftHandPinky1': 'mixamorigLeftHandPinky1',
  'LeftHandPinky2': 'mixamorigLeftHandPinky2',
  'LeftHandPinky3': 'mixamorigLeftHandPinky3',

  // Right Arm
  'RightShoulder': 'mixamorigRightShoulder',
  'RightArm': 'mixamorigRightArm',
  'RightForeArm': 'mixamorigRightForeArm',
  'RightHand': 'mixamorigRightHand',

  // Right Hand Fingers
  'RightHandThumb1': 'mixamorigRightHandThumb1',
  'RightHandThumb2': 'mixamorigRightHandThumb2',
  'RightHandThumb3': 'mixamorigRightHandThumb3',
  'RightHandIndex1': 'mixamorigRightHandIndex1',
  'RightHandIndex2': 'mixamorigRightHandIndex2',
  'RightHandIndex3': 'mixamorigRightHandIndex3',
  'RightHandMiddle1': 'mixamorigRightHandMiddle1',
  'RightHandMiddle2': 'mixamorigRightHandMiddle2',
  'RightHandMiddle3': 'mixamorigRightHandMiddle3',
  'RightHandRing1': 'mixamorigRightHandRing1',
  'RightHandRing2': 'mixamorigRightHandRing2',
  'RightHandRing3': 'mixamorigRightHandRing3',
  'RightHandPinky1': 'mixamorigRightHandPinky1',
  'RightHandPinky2': 'mixamorigRightHandPinky2',
  'RightHandPinky3': 'mixamorigRightHandPinky3',

  // Left Leg
  'LeftUpLeg': 'mixamorigLeftUpLeg',
  'LeftLeg': 'mixamorigLeftLeg',
  'LeftFoot': 'mixamorigLeftFoot',
  'LeftToe': 'mixamorigLeftToeBase',

  // Right Leg
  'RightUpLeg': 'mixamorigRightUpLeg',
  'RightLeg': 'mixamorigRightLeg',
  'RightFoot': 'mixamorigRightFoot',
  'RightToe': 'mixamorigRightToeBase'
};

function handleBVHLoaded(bvh) {
  const loader = new FBXLoader();
  loader.load('/models/girl.fbx', (fbx) => {
    scene.remove(loadedScene);
    loadedScene = fbx;
    scene.add(loadedScene);

    // Set the scale factor to match the FBX model's scale
    const scaleFactor = 100; // Adjust this factor based on your FBX model's scale

    // Apply the mapping to the animation tracks
    const modifiedTracks = bvh.clip.tracks.map(track => {
      for (const [bvhBone, mixamoBone] of Object.entries(boneMap)) {
        if (track.name.startsWith(bvhBone)) {
          track.name = track.name.replace(bvhBone, mixamoBone);

          // Scale position tracks to match the FBX model scale
          if (track.name.endsWith('.position')) {
            const scaledTrack = track.clone();
            for (let i = 0; i < scaledTrack.values.length; i += 3) {
              // Scale x, y, and z components of the position
              scaledTrack.values[i] *= scaleFactor;     // X
              scaledTrack.values[i + 1] *= scaleFactor; // Y
              scaledTrack.values[i + 2] *= scaleFactor; // Z
            }
            return scaledTrack;
          }

          return track;
        }
      }
      return track;
    });

    // Create a new AnimationClip with the scaled and mapped tracks
    const mappedClip = new THREE.AnimationClip(bvh.clip.name, bvh.clip.duration, modifiedTracks);

    // Create an AnimationMixer and apply the mapped clip
    const mixer = new THREE.AnimationMixer(loadedScene);
    mixers.push(mixer);

    const action = mixer.clipAction(mappedClip);
    action.play();
    animationActions.push(action); // Store the action

    // Optional: Capture the animation duration
    animationDetails.duration = mappedClip.duration;
  });
}

function nextFrame() {
  if (currentFrame.value < totalFrames.value - 1) {
    currentFrame.value++;
    updateAnimationFrame();
  }
}

function previousFrame() {
  if (currentFrame.value > 0) {
    currentFrame.value--;
    updateAnimationFrame();
  }
}

function resetToFirstFrame() {
  currentFrame.value = 0;
  updateAnimationFrame();
}

function updateAnimationFrame() {
  const timePerFrame = 1 / 30; 
  const newTime = currentFrame.value * timePerFrame;
  animationActions.forEach(action => {
    action.paused = true;
    action.time = newTime;
    action.play(); 
    action.paused = true; 
  });

  animationDetails.currentTime = newTime;
  progressBarWidth.value = (newTime / animationDetails.duration) * 100;
}


onMounted(() => {
  if (!props.animationId) {
    initThreeJS();
    addGridFloor();
    console.warn("No animation ID provided! Debug mode ON");
  } else {
    const sessionId = store.getters.getSessionId;
    loadModel(sessionId);
  }

  window.addEventListener("resize", onWindowResize);
});

onUnmounted(() => {
  if (renderer) renderer.dispose();
  window.removeEventListener("resize", onWindowResize);
});

function onWindowResize() {
  const width = threejsContainer.value.clientWidth;
  const height = threejsContainer.value.clientHeight;

  camera.aspect = width / height;
  camera.updateProjectionMatrix();
  renderer.setSize(width, height);
}

function initThreeJS() {
  const width = threejsContainer.value.clientWidth;
  const height = threejsContainer.value.clientHeight;

  scene = new THREE.Scene();
  scene.background = new THREE.Color("rgb(220, 220, 220)");
  camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
  camera.position.set(...initialCameraPosition.toArray());

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(width, height);
  threejsContainer.value.appendChild(renderer.domElement);

  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.enablePan = true;

  setupLights();
  animate();
}

function setupLights() {
  const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
  const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.8);
  directionalLight1.position.set(1, 1, 0).normalize();

  const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.8);
  directionalLight2.position.set(-1, 1, 0).normalize(); 

  const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.8);
  directionalLight3.position.set(0, 1, 1).normalize();

  scene.add(ambientLight, directionalLight1, directionalLight2, directionalLight3);
}

async function loadModel(sessionId) {
  isLoading.value = true;

  const modelUrl = `${process.env.VUE_APP_BACKEND_URL}/download-animation/?hashed_id=${props.animationId}&extension=glb`;

  try {
    const response = await fetch(modelUrl, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'session-id': sessionId
      },
    });

    if (response.status === 206) {
      isProcessing.value = true;

      console.warn("Partial content received (206). Retrying after a delay...");

      loadingProgress.value += 2/100;
      
      setTimeout(() => {
        loadModel(sessionId); 
      }, 2000);
      return; 
    } 
    
    if (!response.ok) {
      throw new Error("Failed to load model");
    }

    isProcessing.value = false;

    const contentLength = response.headers.get("content-length");
    totalSize.value = parseInt(contentLength, 10);
    let receivedLength = 0;
    const reader = response.body.getReader();
    
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              isLoading.value = false;
              controller.close();
              return;
            }

            receivedLength += value.length;
            loadedSize.value = receivedLength;
            loadingProgress.value = receivedLength / contentLength;

            controller.enqueue(value);
            push();
          }).catch((error) => {
            console.error("Error while reading the stream:", error);
            isLoading.value = false;
            controller.error(error);
          });
        }
        push();
      },
    });

    const buffer = await new Response(stream).arrayBuffer();

    modelBuffer = buffer;
    const isGLB = checkIfGLB(buffer);
    initThreeJS();
    addGridFloor();

    if (isGLB) {
      const loader = new GLTFLoader();
      loader.parse(buffer, "", (gltf) => {
        handleGLTFLoaded(gltf);
      }, (error) => {
        console.error("Error parsing GLTF model:", error);
      });
    } else {
      const loader = new FBXLoader();
      const fbx = loader.parse(buffer, "");
      handleFBXLoaded(fbx);
    }

  } catch (error) {
    console.error("Error loading model:", error);
    isLoading.value = false;
  }
}


function checkIfGLB(buffer) {
  const header = new Uint8Array(buffer, 0, 4);
  const magic = new TextDecoder("utf-8").decode(header);
  return magic === "glTF";
}

function handleFBXLoaded(fbx) {
  scene.remove(loadedScene);
  fbx.scale.z = -1;
  loadedScene = fbx;
  loadedScene.scale.x = -loadedScene.scale.x;
  scene.add(loadedScene);
  setupFBXModel();
}

function setupFBXModel() {
  if (loadedScene.animations && loadedScene.animations.length) {
    setupAnimations();
  }
  adjustCameraToFitModel();
}

function handleGLTFLoaded(gltf) {
  scene.remove(loadedScene);
  loadedScene = gltf.scene;
  scene.add(loadedScene);
  setupGLTFModel(gltf);
}

function setupGLTFModel(gltf) {
  if (gltf.animations && gltf.animations.length) {
    setupGLTFAnimations(gltf.animations);
  }
  adjustCameraToFitModel();
}

function setupGLTFAnimations(animations) {
  skeletonHelper = new THREE.SkeletonHelper(loadedScene);
  scene.add(skeletonHelper);

  const hasGeometry = hasVisibleGeometry(loadedScene);
  if (!hasGeometry) {
    console.warn("No visible geometry linked to the skeleton. Showing skeleton.");
    skeletonHelper.visible = true;
  } else {
    skeletonHelper.visible = false;
  }

  const mixer = new THREE.AnimationMixer(loadedScene);
  mixers.push(mixer);
  animations.forEach((clip) => {
    const action = mixer.clipAction(clip);
    action.play();
    animationActions.push(action);
  });

  animationDetails.duration = mixer.clipAction(animations[0]).getClip().duration;
}


function hasVisibleGeometry(scene) {
  let hasGeometry = false;
  scene.traverse((object) => {
    if (object.isMesh && object.visible) {
      hasGeometry = true;
    }
  });
  return hasGeometry;
}


function setupAnimations() {
  skeletonHelper = new THREE.SkeletonHelper(loadedScene);
  skeletonHelper.visible = false;
  scene.add(skeletonHelper);

  const mixer = new THREE.AnimationMixer(loadedScene);
  mixers.push(mixer);
  loadedScene.animations.forEach((clip) => {
    const action = mixer.clipAction(clip);
    action.play();
    animationActions.push(action); // Store the action
  });

  animationDetails.duration = mixer.clipAction(loadedScene.animations[0]).getClip().duration;
}

function adjustCameraToFitModel() {
  let targetObject = loadedScene; // Default to loaded scene
  if (!hasVisibleGeometry(loadedScene)) {
    if (skeletonHelper) {
      console.log("No geometry found, adjusting to skeleton.");
      targetObject = skeletonHelper; // Adjust camera to fit skeleton if no geometry
    }
  }

  const box = new THREE.Box3().setFromObject(targetObject);
  const size = box.getSize(new THREE.Vector3());
  const center = box.getCenter(new THREE.Vector3());
  const distance = size.length() * 1.5; // Adjust distance as needed

  if (distance === 0) {
    console.warn("Calculated distance is zero. Defaulting to a basic distance.");
    camera.position.set(center.x, center.y, center.z + 10); // Default distance
  } else {
    camera.position.set(center.x, center.y, center.z + distance);
  }

  console.log(`Camera set to: ${camera.position.toArray()}`);
  console.log(`Looking at: ${center.toArray()}`);
  console.log(`Distance: ${distance}, Size: ${size.toArray()}`);

  camera.lookAt(center);
  controls.target.copy(center);
  controls.update();
}


function formatTime(seconds) {
  const pad = (num) => num.toString().padStart(2, "0");
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = Math.floor(seconds % 60);
  return `${pad(hours)}:${pad(minutes)}:${pad(secondsLeft)}`;
}

function animate() {
  requestAnimationFrame(animate);
  const delta = clock.getDelta();

  // Update based on whether the animation is playing
  if (isAnimationPlaying.value) {
    mixers.forEach(mixer => mixer.update(delta));
  } else {
    // When paused, we still need to force a zero-delta update if there has been a change
    mixers.forEach(mixer => mixer.update(0));
  }

  // Always update the progress bar and time display
  if (animationActions.length > 0) {
    const currentMixerTime = animationActions[0].time % animationDetails.duration;
    animationDetails.currentTime = currentMixerTime;
    progressBarWidth.value = (currentMixerTime / animationDetails.duration) * 100;
  }

  controls.update();
  renderer.render(scene, camera);
}

function zoomIn() {
  const direction = new THREE.Vector3().subVectors(camera.position, controls.target).normalize();
  camera.position.addScaledVector(direction, -zoomStep);
  controls.update();
}

function zoomOut() {
  const direction = new THREE.Vector3().subVectors(camera.position, controls.target).normalize();
  camera.position.addScaledVector(direction, zoomStep);
  controls.update();
}

function togglePanMode() {
  isPanModeEnabled.value = !isPanModeEnabled.value;
  controls.mouseButtons = {
    LEFT: THREE.MOUSE.ROTATE,
    MIDDLE: THREE.MOUSE.DOLLY,
    RIGHT: THREE.MOUSE.PAN,
  };

  if (isPanModeEnabled.value) {
    controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
    controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;
  }

  controls.update();
}

function toggleSkeletonVisibility() {
  const hasGeometry = hasVisibleGeometry(loadedScene);

  if (!hasGeometry) {
    return;
  }
  
  if (skeletonHelper) {
    skeletonHelper.visible = !skeletonHelper.visible;
  }

  if (loadedScene) {
    loadedScene.visible = !loadedScene.visible;
  }

  controls.update();
}

function toggleAnimation() {
  isAnimationPlaying.value = !isAnimationPlaying.value;
  iconName.value = isAnimationPlaying.value ? "pause" : "play";

  animationActions.forEach(action => {
    action.paused = !isAnimationPlaying.value;
    if (!isAnimationPlaying.value) {
      action.play(); // This might seem counterintuitive but necessary to update the frame
    }
  });

  // If toggling to paused, ensure the current frame aligns with the paused time
  if (!isAnimationPlaying.value) {
    currentFrame.value = Math.round(animationDetails.currentTime * 30); // Adjust for fps
  }
}


function addGridFloor() {
  const gridHelper = new THREE.GridHelper(500, 50, 0x888888, 0x444444);
  gridHelper.position.y = 0;
  gridHelper.material.opacity = 0.75;
  gridHelper.material.transparent = true;
  scene.add(gridHelper);
}

function resetCamera() {
  adjustCameraToFitModel();
}

function setAnimationProgress(event) {
  const bounds = event.currentTarget.getBoundingClientRect();
  const clickX = event.clientX - bounds.left;
  const progressPercentage = clickX / bounds.width;
  const newTime = progressPercentage * animationDetails.duration;

  // Update time on all actions
  animationActions.forEach(action => {
    action.paused = true; // Pause the action
    action.time = newTime; // Set the current time
    action.play(); // Restart the action from the new time
    action.paused = !isAnimationPlaying.value; // Maintain the playing/paused state
  });

  // Update UI state
  animationDetails.currentTime = newTime;
  progressBarWidth.value = progressPercentage * 100;
}

function handleDragOver(event) {
  event.preventDefault();
}

function handleFileDrop(event) {
  event.preventDefault();
  const files = event.dataTransfer.files;
  if (files.length > 0) {
    const file = files[0];
    if (file.name.toLowerCase().endsWith(".bvh")) {
      loadBVHFile(file);
    } else if (
      file.name.toLowerCase().endsWith(".gltf") ||
      file.name.toLowerCase().endsWith(".glb")
    ) {
      loadGLTFFile(file);
    } else {
      loadFBXFile(file);
      alert("Please drop a valid GLB or BVH file.");
    }
  } else {
    alert("No file dropped.");
  }
}

function loadFBXFile(file) {
  const reader = new FileReader();
  reader.onload = (event) => {
    const buffer = event.target.result;
    const loader = new FBXLoader();
    const fbx = loader.parse(buffer, "");
    handleFBXLoaded(fbx);
  };
  reader.readAsArrayBuffer(file);
}

function loadGLTFFile(file) {
  const reader = new FileReader();
  reader.onload = (event) => {
    const buffer = event.target.result;
    const loader = new GLTFLoader();
    loader.parse(
      buffer,
      "",
      (gltf) => {
        handleGLTFLoaded(gltf);
      },
      (error) => {
        console.error("Error loading GLTF model:", error);
      }
    );
  };
  reader.readAsArrayBuffer(file);
}

function downloadModel() {
  if (!modelBuffer) {
    console.error("Model buffer is not available.");
    return;
  }

  const isGLB = checkIfGLB(modelBuffer);
  const blob = new Blob([modelBuffer], { type: isGLB ? 'model/gltf-binary' : 'model/fbx' });
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.href = URL.createObjectURL(blob);
  link.download = isGLB ? 'model.glb' : 'model.fbx';
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

function startDragging(event) {
  dragging.value = true;
  updateAnimationProgress(event);
}

function dragUpdate(event) {
  if (dragging.value) {
    updateAnimationProgress(event);
  }
}

function stopDragging() {
  dragging.value = false;
}

function updateAnimationProgress(event) {
  const bounds = event.currentTarget.getBoundingClientRect();
  const clickX = event.clientX - bounds.left;
  const progressPercentage = clickX / bounds.width;
  const newTime = progressPercentage * animationDetails.duration;

  // Update time on all actions
  animationActions.forEach(action => {
    action.paused = true; // Pause the action
    action.time = newTime; // Set the current time
    action.play(); // Restart the action from the new time
    action.paused = !isAnimationPlaying.value; // Maintain the playing/paused state
  });

  // Update UI state
  animationDetails.currentTime = newTime;
  progressBarWidth.value = progressPercentage * 100;
}

defineExpose({
  downloadModel
});
</script>



<style scoped>
.anim-controls {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.control-btn {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  background-color: white;
  height: .7rem;
  width: 0%;
  min-width: 4px;
}

.animation-wrapper {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.threejs-container {
  margin: auto;
  width: 90%;
  height: 70vh;
}

@media (max-height: 750px) {
    .threejs-container {
    margin: auto;
    width: 90%;
    height: 60vh;
  }
}

.animation-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playbar {
  width: 40%;
}

.progress-container {
  flex-grow: 1;
  height: .7rem;
  background-color: darkgrey;
  border-radius: 4px;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
  user-select: none; 
}

.time-codes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playbar-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.caption {
  color: rgba(255, 255, 255, .4);
}

.loader-description {
  margin-bottom: 10px;
}

.locked {
  cursor: not-allowed;
}

.rotate {
  transform: rotate(180deg);
}

.progress-placeholder {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: padding-box;
  background-position: center;
  border: 1rem solid rgba(255, 255, 255, 0.1); 
}

.progress-circle-bg,
.progress-circle-fg {
  transition: stroke-dashoffset 0.3s linear;
}
</style>
